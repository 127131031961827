import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import ProductOutlined from '@ant-design/icons/ProductOutlined';
import { memo, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import { FaRegAddressCard, FaRegBuilding } from 'react-icons/fa';
import { GrUserNew, GrUserSettings } from 'react-icons/gr';
import { LuComputer } from 'react-icons/lu';
import { TbBuildingSkyscraper } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import PageSideTabs from '~/components/PageSideTabs';
import {
  SettingsGridDiv,
  SettingsStickyDiv,
  SettingsTabContentDiv,
  SettingsTabTitleH3,
} from '~/components/settings/shared';
import routes, { type CompanySettingsTab } from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';

import TabAdmins from './components/TabAdmins';
import TabAgents from './components/TabAgents';
import TabBrainProfiles from './components/TabBrainProfiles';
import TabCompany from './components/TabCompanyDetails';
import TabEmergencyContacts from './components/TabEmergencyContacts';
import TabIntegrations from './components/TabIntegrations';
import TabSubsidiaries from './components/TabSubsidiaries';
import TabSubsidiaryDetails from './components/TabSubsidiaryDetails';

const CompanySettingsPage = memo(() => {
  const { companyFeatures } = useCompanyFeatures();
  const { currentCompany } = useCompany();
  const { isSuperAdmin, isCompanyAdmin, isSubsidiaryAdmin } = useCurrentUserContext();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [tab, setTab] = useState<CompanySettingsTab>(
    (queryParams.tab as CompanySettingsTab) || 'company-details',
  );

  const isCompanyAdminOrSuperAdmin = !!currentCompany?.id && (isSuperAdmin || isCompanyAdmin);

  const tabs: {
    key: CompanySettingsTab;
    icon: ReactNode;
  }[] = useMemo(() => {
    const items: {
      key: CompanySettingsTab;
      icon: ReactNode;
      isShown: boolean;
    }[] = [
      {
        key: 'company-details',
        icon: <FaRegBuilding />,
        isShown: true,
      },
      {
        key: 'subsidiary-details',
        icon: <FaRegAddressCard />,
        isShown: isSubsidiaryAdmin,
      },
      {
        key: 'subsidiaries',
        icon: <TbBuildingSkyscraper />,
        isShown: isCompanyAdminOrSuperAdmin,
      },
      {
        key: 'admins',
        icon: <GrUserSettings />,
        isShown: true,
      },
      {
        key: 'agents',
        icon: <GrUserNew />,
        isShown: true,
      },
      {
        key: 'brain-profiles',
        icon: <ProductOutlined />,
        isShown: isCompanyAdminOrSuperAdmin && companyFeatures.brainProfilesPage,
      },
      {
        key: 'emergency-contacts',
        icon: <PhoneOutlined />,
        isShown: companyFeatures.contactsOnCall,
      },
      {
        key: 'integrations',
        icon: <LuComputer />,
        isShown: companyFeatures.integrationsPage,
      },
    ];
    return items.filter((item) => item.isShown);
  }, [
    companyFeatures.brainProfilesPage,
    companyFeatures.contactsOnCall,
    companyFeatures.integrationsPage,
    isCompanyAdminOrSuperAdmin,
    isSubsidiaryAdmin,
  ]);

  useEffect(() => {
    if (queryParams.tab) {
      setTab(queryParams.tab as CompanySettingsTab);
    }
  }, [queryParams.tab]);

  const handleTabSelect = useCallback(
    (selectedTab: CompanySettingsTab) => {
      setTab(selectedTab);
      navigate(routes.companySettings({ ...queryParams, tab: selectedTab }));
    },
    [navigate, queryParams],
  );

  return (
    <PageContentWrapper>
      <Container>
        <SettingsGridDiv>
          <aside>
            <PageHeader
              title={i18n.t('companySettingsPage.title')}
              subtitle={i18n.t('companySettingsPage.subtitle')}
            />
            <SettingsStickyDiv>
              <PageSideTabs
                activeItemKey={tab}
                items={tabs.map(({ key, icon }) => ({
                  key,
                  dataId: `company-settings-${key}-link`,
                  icon,
                  label: i18n.t(`companySettingsPage.tabs.${key}`),
                  onClick: () => handleTabSelect(key),
                }))}
              />
            </SettingsStickyDiv>
          </aside>
          <SettingsTabContentDiv>
            <SettingsTabTitleH3 data-id="company-settings-page-tab-title">
              {i18n.t(`companySettingsPage.tabs.${tab}`)}
            </SettingsTabTitleH3>
            {tab === 'company-details' && <TabCompany />}
            {tab === 'subsidiary-details' && <TabSubsidiaryDetails />}
            {tab === 'subsidiaries' && <TabSubsidiaries isBackofficePage={false} />}
            {tab === 'admins' && <TabAdmins isBackofficePage={false} />}
            {tab === 'agents' && <TabAgents isBackofficePage={false} />}
            {tab === 'brain-profiles' && <TabBrainProfiles isBackofficePage={false} />}
            {tab === 'emergency-contacts' && <TabEmergencyContacts />}
            {tab === 'integrations' && <TabIntegrations />}
          </SettingsTabContentDiv>
        </SettingsGridDiv>
      </Container>
    </PageContentWrapper>
  );
});

CompanySettingsPage.displayName = 'CompanySettingsPage';

export default CompanySettingsPage;
