import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Button, Input, InputRef, TableColumnType } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';

import i18n from '~/locales/i18n';

interface GetColumnSearchPropsParams<T> {
  dataIndex: string;
  title: string;
  renderWithHighlight?: ({
    text,
    highlightedNode,
    record,
  }: {
    text: string;
    highlightedNode: ReactNode;
    record: T;
  }) => ReactNode;
}

export default function useTableSearch<T extends Record<string, any>>(): {
  getColumnSearchProps: ({
    dataIndex,
    renderWithHighlight,
  }: GetColumnSearchPropsParams<T>) => TableColumnType<T>;
} {
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  const searchInput = useRef<InputRef>(null);

  const handleSearch = useCallback(
    ({
      dataIndex,
      value,
      setSelectedKeys,
      confirm,
    }: {
      dataIndex: string;
      value: string;
      setSelectedKeys: (selectedKeys: string[]) => void;
      confirm: FilterDropdownProps['confirm'];
    }) => {
      const newSelectedKeys: string[] = value ? [value] : [];
      setSelectedKeys(newSelectedKeys);
      setSearchText(newSelectedKeys[0]);
      setSearchedColumn(dataIndex);
      confirm({ closeDropdown: false });
    },
    [],
  );

  const getColumnSearchProps = useCallback(
    ({
      dataIndex,
      title,
      renderWithHighlight,
    }: GetColumnSearchPropsParams<T>): TableColumnType<T> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            confirm?.();
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            style={{ padding: 8, display: 'grid', gridTemplateColumns: '1fr auto', gridGap: 8 }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              size="middle"
              allowClear
              onClear={() => {
                setSelectedKeys([]);
                setSearchText('');
                clearFilters?.();
                confirm?.();
              }}
              ref={searchInput}
              placeholder={`${i18n.t<string>('common.search')} ${title.toLowerCase()}`}
              value={selectedKeys[0]}
              onChange={(event) => {
                handleSearch({
                  dataIndex,
                  value: event.target.value,
                  setSelectedKeys,
                  confirm,
                });
              }}
            />
            <Button
              type="default"
              size="middle"
              onClick={() => {
                confirm?.();
              }}
            >
              {i18n.t('common.close')}
            </Button>
          </div>
        </form>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record?.[dataIndex]
          ?.toString()
          ?.toLowerCase()
          ?.trim()
          ?.includes((value as string)?.toLowerCase()?.trim()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text: string, record: T) => {
        const highlightedNode: ReactNode =
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          );
        return renderWithHighlight
          ? renderWithHighlight({ text, record, highlightedNode })
          : highlightedNode;
      },
    }),
    [searchText, searchedColumn, handleSearch],
  );

  return useMemo(() => ({ getColumnSearchProps }), [getColumnSearchProps]);
}
