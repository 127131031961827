import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { useReactiveVar } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import { alertSoundMutedVar } from '~/apollo/reactiveVariables/alertSoundMutedVar';
import AlarmBottomBannerIcon from '~/components/icons/AlarmBottomBannerIcon';
import Link from '~/components/Link';
import TimeAgo from '~/components/TimeAgo';
import routes from '~/config/routes';
import useAlarmsContext from '~/context/useAlarmsContext';
import useModalsContext from '~/context/useModalsContext';
import usePrevious from '~/hooks/usePrevious';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_LEVEL } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getAlarmsSortedByLevelAndCreatedAt from '~/utils/alarm/getAlarmsSortedByLevelAndCreatedAt';
import getHighestAlarmLevel from '~/utils/alarm/getHighestAlarmLevel';
import { formatDateTime } from '~/utils/dateTime';
import { muteAlarmSound, unmuteAlarmSound } from '~/utils/sounds';
import getUserName from '~/utils/user/getUserName';

import useAlarmSound from './hooks/useAlarmSound';

const WrapperDiv = styled.div<{ $alarmColor: string }>`
  width: 100%;
  border-top: 2px solid ${(props) => props.$alarmColor};
  bottom: 0;
  height: ${theme.dimensions.alarmsBottomBannerHeight}px;
  z-index: ${theme.layers.alarmsBottomBanner};
  display: grid;
  position: fixed;
  background: ${theme.colors.white};
  grid-template-columns: minmax(0, 240px) minmax(0, 1fr);

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 40px) minmax(0, 1fr);
  }
`;

const LeftDiv = styled.div<{ $alarmColor: string }>`
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  align-items: center;
  background-color: ${(props) => props.$alarmColor};
  padding-left: 8px;
  padding-right: 24px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
    padding: 0;
  }
`;

const MuteStyledButton = styled.button`
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 20px;
  padding: 0 20px;
  height: 60px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.medias.lteSmall} {
    padding: 0;
  }
`;

const LeftColumnContentDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${theme.medias.lteSmall} {
    display: none;
  }
`;

const AlarmCountSpan = styled.span`
  color: ${theme.colors.white};
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 14px;
`;

const ViewAgentsStyledLink = styled(Link)`
  color: ${theme.colors.white};
  text-align: center;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    color: ${theme.colors.white};
  }
`;

const RightDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  align-items: center;
`;

const AlertIconDiv = styled.div`
  margin-right: 4px;

  ${theme.medias.lteSmall} {
    display: none;
  }
`;

const FONT_SIZE = '16px';

const DetailsFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${FONT_SIZE};
  color: ${theme.colors.darkBlue};
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const sharedLinkStyles = css`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: ${FONT_SIZE};
  color: ${theme.colors.primaryBlue};
  text-decoration: underline !important;
  transition: all 0.05s ease-in-out;

  &:hover {
    color: ${theme.colors.primaryBlue};
    text-decoration: underline;
  }
`;

const StyledAgentLink = styled(Link)`
  ${sharedLinkStyles}
`;

const StyledAlarmModalButton = styled.button`
  ${sharedLinkStyles}
`;

const NavigationButtonsNav = styled.nav`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, auto));
  gap: 6px;
  align-items: center;
  color: ${theme.colors.darkBlue};
  font-size: 18px;
  background: ${theme.colors.white};
  padding-right: 15px;

  ${theme.medias.lteSmall} {
    padding-right: 10px;
  }
`;

const AlarmsBottomBanner = memo(() => {
  const { openModal } = useModalsContext();
  const { ongoingAlarms, hasAlertBottomBanner } = useAlarmsContext();

  useAlarmSound();

  const filteredOngoingAlarms = useMemo(
    () =>
      getAlarmsSortedByLevelAndCreatedAt(ongoingAlarms).filter(
        (alarm) => alarm.level !== ALARM_LEVEL.Information,
      ),
    [ongoingAlarms],
  );

  const [navigationPage, setNavigationPage] = useState<number>(1);

  const previousOngoingAlarmsLength = usePrevious(filteredOngoingAlarms?.length);

  const selectedAlarm = useMemo(
    () => filteredOngoingAlarms?.[navigationPage - 1],
    [navigationPage, filteredOngoingAlarms],
  );

  useEffect(() => {
    if ((previousOngoingAlarmsLength || 0) < (filteredOngoingAlarms?.length || 0)) {
      setNavigationPage(1);
    }
  }, [previousOngoingAlarmsLength, filteredOngoingAlarms?.length]);

  useEffect(() => {
    if (navigationPage > filteredOngoingAlarms.length) {
      setNavigationPage(filteredOngoingAlarms.length);
    }
  }, [navigationPage, filteredOngoingAlarms.length]);

  const alarmColor = useMemo(
    () => getAlarmColorFromLevel(selectedAlarm?.level || ALARM_LEVEL.Critical),
    [selectedAlarm?.level],
  );

  const highestLevelAlarm = useMemo(
    () => getHighestAlarmLevel(filteredOngoingAlarms),
    [filteredOngoingAlarms],
  );
  const isMuteDisabled = highestLevelAlarm !== ALARM_LEVEL.Critical;

  const alertSoundMutedFromVar = useReactiveVar(alertSoundMutedVar);
  const isMuted = isMuteDisabled ? true : alertSoundMutedFromVar;

  const toggleMuteAlarmSound = useCallback(() => {
    if (!isMuteDisabled) {
      if (isMuted) {
        unmuteAlarmSound();
      } else {
        muteAlarmSound();
      }
    }
  }, [isMuteDisabled, isMuted]);

  const showNaviationButtons =
    filteredOngoingAlarms.length > 1 && navigationPage <= filteredOngoingAlarms.length;

  if (!hasAlertBottomBanner) {
    return null;
  }

  return (
    <WrapperDiv $alarmColor={alarmColor.text} data-id="alarms-bottom-banner-wrapper">
      <LeftDiv $alarmColor={alarmColor.text}>
        <Tooltip
          title={
            isMuteDisabled
              ? undefined
              : i18n.t(
                  isMuted ? 'muteButton.unmuteTooltipMessage' : 'muteButton.muteTooltipMessage',
                )
          }
          placement="topLeft"
        >
          <MuteStyledButton
            onClick={toggleMuteAlarmSound}
            data-id="mute-btn"
            style={{
              fontSize: '20px',
              cursor: isMuteDisabled ? 'default' : 'pointer',
            }}
          >
            {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
          </MuteStyledButton>
        </Tooltip>
        <LeftColumnContentDiv>
          <AlarmCountSpan data-id="alarms-bottom-banner-alarm-count">
            {i18n.t('alarmsBottomBanner.alarmCount', { count: filteredOngoingAlarms.length })}
          </AlarmCountSpan>
          <ViewAgentsStyledLink to={routes.agents} data-id="view-all-agents-link">
            {i18n.t('alarmsBottomBanner.viewAllAgents')}
          </ViewAgentsStyledLink>
        </LeftColumnContentDiv>
      </LeftDiv>
      <RightDiv>
        <DetailsFlexDiv>
          <AlertIconDiv>
            <AlarmBottomBannerIcon color={alarmColor.text} size={26} />
          </AlertIconDiv>
          {selectedAlarm && (
            <>
              <Tooltip title={i18n.t('alarmsPage.card.viewAgent')}>
                <span>
                  <StyledAgentLink
                    to={routes.status({ id: selectedAlarm?.carrier?.id })}
                    data-id="alarms-bottom-banner-view-agent-blue-link"
                  >
                    {selectedAlarm?.carrier?.completeName}
                  </StyledAgentLink>
                </span>
              </Tooltip>
              <span>|</span>
              <Tooltip title={i18n.t('carrierDetailsPopup.manageAlarm')}>
                <span>
                  <StyledAlarmModalButton
                    data-id="alarms-bottom-banner-manage-alarm-blue-link"
                    onClick={() => {
                      openModal({
                        type: 'alarm',
                        defaultTab: 'manage',
                        alarmId: selectedAlarm?.id,
                        agentId: selectedAlarm?.carrier?.id,
                      });
                    }}
                  >
                    {i18n.t(`general.generalAlarms.${selectedAlarm?.type}`)}
                  </StyledAlarmModalButton>
                </span>
              </Tooltip>
              <span>|</span>
              <span>
                <Tooltip title={formatDateTime(selectedAlarm?.created_at)}>
                  <span style={{ cursor: 'help' }}>
                    <TimeAgo date={selectedAlarm?.created_at} />
                  </span>
                </Tooltip>{' '}
                {selectedAlarm?.administrator && (
                  <span data-id="alarm-assignee">
                    ({i18n.t('general.generalAlarms.alarmAssigned')}{' '}
                    <b>{getUserName(selectedAlarm?.administrator)}</b>)
                  </span>
                )}
              </span>
            </>
          )}
        </DetailsFlexDiv>
        {showNaviationButtons && (
          <NavigationButtonsNav>
            <Tooltip title={i18n.t('general.pagination.previous')} placement="top">
              <div>
                <Button
                  data-id="alarms-bottom-banner-previous-btn"
                  size="middle"
                  disabled={false}
                  icon={<LeftOutlined />}
                  onClick={() => {
                    setNavigationPage((prevNavigationPage) => {
                      const nextPage = prevNavigationPage - 1;
                      return nextPage < 1 ? filteredOngoingAlarms.length : nextPage;
                    });
                  }}
                />
              </div>
            </Tooltip>
            <div>
              <span>{navigationPage}</span>
              <span>/</span>
              <span>{filteredOngoingAlarms.length}</span>
            </div>
            <Tooltip title={i18n.t('general.pagination.next')} placement="top">
              <div>
                <Button
                  data-id="alarms-bottom-banner-next-btn"
                  size="middle"
                  disabled={false}
                  icon={<RightOutlined />}
                  onClick={() => {
                    setNavigationPage((prevNavigationPage) => {
                      const nextPage = prevNavigationPage + 1;
                      return nextPage > filteredOngoingAlarms.length ? 1 : nextPage;
                    });
                  }}
                />
              </div>
            </Tooltip>
          </NavigationButtonsNav>
        )}
      </RightDiv>
    </WrapperDiv>
  );
});

AlarmsBottomBanner.displayName = 'AlarmsBottomBanner';

export default AlarmsBottomBanner;
