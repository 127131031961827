import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Table, Tooltip, Button, type TableColumnsType, Tag } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';

import useMutationDeleteCompany from '~/apollo/hooks/company/useMutationDeleteCompany';
import useQueryCompanyListWithDetails, {
  type CompanyListWithDetailsQueryData,
} from '~/apollo/hooks/company/useQueryCompanyListWithDetails';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SettingsTopFlexDiv,
  SettingsButtonsFlexDiv,
  SelectCompanySubsidiaryDiv,
} from '~/components/settings/shared';
import TableInfoListButton from '~/components/TableInfoListButton';
import TimeAgo from '~/components/TimeAgo';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useTableSearch from '~/hooks/useTableSearch';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';
import defaultTablePaginationProps from '~/utils/table/defaultTablePaginationProps';

import { TextAvatarDiv } from '../TabSuperAdmins/TabSuperAdmins';

interface TableDataType {
  key: Key;
  name: string;
  id: string;
  companyAdmins: CompanyListWithDetailsQueryData['getAllCompanies']['items'][0]['admins']['items'];
  companyAdminsCount: number;
  subsidiaries: CompanyListWithDetailsQueryData['getAllCompanies']['items'][0]['subsidiaries']['items'];
  subsidiariesCount: number;
  createdAt: string;
  actions: ReactNode;
}

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table<TableDataType>)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const TabCompanies = memo(() => {
  const { isSuperAdmin, isLoading: isLoadingCurrentUser } = useCurrentUserContext();
  const { openModal } = useModalsContext();
  const {
    companyListWithDetails,
    isLoading: isLoadingCompanyList,
    refetchCompanyListWithDetails,
  } = useQueryCompanyListWithDetails();
  const { currentCompany, refetchCompanyList } = useCompany();
  const { deleteCompany, isLoading: isLoadingDeleteCompany } = useMutationDeleteCompany();

  const isLoading = isLoadingCurrentUser || isLoadingCompanyList || isLoadingDeleteCompany;

  const totalRefetch = useCallback(async () => {
    await Promise.all([refetchCompanyList(), refetchCompanyListWithDetails()]);
  }, [refetchCompanyList, refetchCompanyListWithDetails]);

  const { getColumnSearchProps } = useTableSearch<TableDataType>();

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'name',
        title: i18n.t('common.name'),
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps({
          dataIndex: 'name',
          title: i18n.t('common.name'),
          renderWithHighlight: ({ text, record, highlightedNode }) =>
            text ? (
              <TextAvatarDiv>
                <ReactAvatar name={text} size="26px" /> {highlightedNode}
                {currentCompany?.id === record.id && (
                  <Tag color="green">{i18n.t('common.selected')}</Tag>
                )}
              </TextAvatarDiv>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'subsidiariesCount',
        title: i18n.t('backofficeCompaniesPage.subsidiaries'),
        sorter: (a, b) => a.subsidiariesCount - b.subsidiariesCount,
        render: (text, record) => {
          const sortedSubsidiaries = orderBy(
            record.subsidiaries,
            [(item) => item.name.toLowerCase()],
            ['asc'],
          );

          return (
            <TableInfoListButton
              buttonText={text}
              modalTitle={`${i18n.t('backofficeCompaniesPage.companySubsidiaries')} (${record.name})`}
              modalSubtitle={
                <>
                  {i18n.t('backofficeCompaniesPage.subsidiaries')} ({text})
                </>
              }
              modalList={sortedSubsidiaries.map((subsidiary) => subsidiary.name || subsidiary.id)}
            />
          );
        },
      },
      {
        dataIndex: 'companyAdminsCount',
        title: i18n.t('backofficeCompaniesPage.admins'),
        sorter: (a, b) => a.companyAdminsCount - b.companyAdminsCount,
        render: (text, record) => {
          const sortedAdmins = orderBy(
            record.companyAdmins,
            [(item) => item.email.toLowerCase()],
            ['asc'],
          );

          return (
            <TableInfoListButton
              buttonText={text}
              modalTitle={`${i18n.t('backofficeCompaniesPage.companyAdmins')} (${record.name})`}
              modalSubtitle={
                <>
                  {i18n.t('backofficeCompaniesPage.admins')} ({text})
                </>
              }
              modalList={sortedAdmins.map((admin) => admin.email || admin.id)}
            />
          );
        },
      },
      {
        dataIndex: 'createdAt',
        title: i18n.t('common.created'),
        sorter: (a, b) => a.createdAt.toLowerCase().localeCompare(b.createdAt.toLowerCase()),
        render: (text) => (
          <Tooltip title={formatDateTime(text)}>
            <span style={{ cursor: 'help' }}>
              <TimeAgo date={text} />
            </span>
          </Tooltip>
        ),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
      },
    ],
    [currentCompany?.id, getColumnSearchProps],
  );

  const data: TableDataType[] = useMemo(
    () =>
      companyListWithDetails.map((company) => {
        const canBeDeleted =
          company.subsidiaries.items.length === 0 && company.admins.items.length === 0;

        return {
          key: company.id,
          name: company.name,
          id: company.id,
          companyAdmins: company.admins.items,
          companyAdminsCount: company.admins.items.length,
          subsidiaries: company.subsidiaries.items,
          subsidiariesCount: company.subsidiaries.items.length,
          createdAt: company.created_at,
          actions: (
            <ActionsDiv>
              <Tooltip title={i18n.t('backofficeCompaniesPage.editCompany')}>
                <Button
                  onClick={() => {
                    openModal({
                      type: 'companyAddEdit',
                      companyId: company.id,
                      refetchOnCompleted: totalRefetch,
                    });
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Tooltip
                title={
                  canBeDeleted
                    ? i18n.t('backofficeCompaniesPage.deleteCompany')
                    : i18n.t('backofficeCompaniesPage.cannotDeleteCompany')
                }
              >
                <Button
                  danger
                  disabled={!canBeDeleted}
                  onClick={() => {
                    openModal({
                      type: 'confirmation',
                      title: i18n.t('backofficeCompaniesPage.deleteCompany'),
                      description: (
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i18n.t<string>(
                              'backofficeCompaniesPage.areYouSureYouWantToDeleteCompanyHtml',
                              {
                                companyName: company.name,
                              },
                            ),
                          }}
                        />
                      ),
                      actionType: 'danger',
                      action: async () => {
                        try {
                          await deleteCompany({
                            variables: {
                              company_id: company.id,
                            },
                          });
                          await totalRefetch();
                          notification.success({
                            message: i18n.t('common.success'),
                            description: i18n.t('backofficeCompaniesPage.companyDeletedSuccess'),
                          });
                        } catch (error) {
                          notification.error({
                            message: (error as any)?.message || i18n.t('common.error'),
                          });
                        }
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </ActionsDiv>
          ),
        };
      }),
    [companyListWithDetails, deleteCompany, openModal, totalRefetch],
  );

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv />
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('backofficeCompaniesPage.listOfCompanies')} (${companyListWithDetails.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={async () => {
              await totalRefetch();
            }}
          >
            {i18n.t('common.refresh')}
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              openModal({
                type: 'companyAddEdit',
                companyId: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
          >
            {i18n.t('backofficeCompaniesPage.createCompany')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={defaultTablePaginationProps}
      />
    </WrapperDiv>
  );
});

TabCompanies.displayName = 'TabCompanies';

export default TabCompanies;
